<template>
  <div>
    <h1>SyncUserTableToFireStore</h1>
    <br />
    <p>Input: <input type="file" @change="previewFiles" /></p>
    <button v-on:click="syncUser" class="btn btn-light-info">Run Sync</button>
    <table class="table" v-if="masterData">
      <tr>
        <th>uid</th>
        <th>email</th>
        <th>displayName</th>
        <th>type</th>
        <th>phone</th>
        <th>photoURL</th>
      </tr>
      <tr
        v-for="(value, key) in masterData"
        :key="key"
        v-bind:class="{ tactive: listUser.includes(value.uid) }"
      >
        <td>{{ value.uid }}</td>
        <td>{{ value.email }}</td>
        <td>{{ value.displayName }}</td>
        <td>{{ value.type }}</td>
        <td>{{ value.phone }}</td>
        <td>{{ value.photoURL }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import user from "../mixins/user";
import firebase from "../firebase";
import "firebase/compat/firestore";
const db = firebase.firestore();
export default {
  mixins: [user],
  data() {
    return {
      listUser: [],
      masterData: [],
    };
  },
  methods: {
    previewFiles(event) {
      let that = this;
      if (event.target.files && event.target.files[0]) {
        var myFile = event.target.files[0];
        var reader = new FileReader();

        reader.addEventListener("load", function (e) {
          that.masterData = JSON.parse(e.target.result);
        });

        reader.readAsText(myFile);
      } else {
        that.masterData = [];
      }
    },
    syncUser() {
      let batch = db.batch();
      let that = this;

      if (that.masterData.length == 0) {
        alert("Please select json file");
        return;
      }
      console.log("start run");
      for (let index = 0; index < that.masterData.length; index++) {
        const user = that.masterData[index];

        let nycRef = db.collection("users").doc(String(user.uid));
        batch.set(nycRef, {
          uid: Number(user.uid),
          displayName: user.displayName,
          photoURL: user.photoURL ? user.photoURL : null,
          email: user.email ? user.email : null,
          type: user.type,
        });

        that.listUser.push(user.uid);
      }

      // Commit the batch
      batch.commit().then(() => {
        console.log("done");
        alert("Sync Done");
      });
    },
  },
};
</script>
<style lang="scss">
.tactive {
  background: cadetblue;
}
</style>
