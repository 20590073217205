<template>
  <SyncUser />
</template>

<script>
import SyncUser from "../../../plugins/prepChat/components/SyncUser.vue";
export default {
  components: {
    SyncUser,
  },
};
</script>

<style></style>
